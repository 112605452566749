import '@js/modules/polyfill'
import '@js/modules/skip-link-focus-fix.js'
import Promise from 'es6-promise-promise'
import modernizr from '@js/vendor/modernizr-3.6.0.min.js'
import throttle from 'lodash/throttle'
// import debounce from 'lodash/debounce'
import RM from '@js/vendor/RM'
import Util from '@js/vendor/Util'
import DOMAnimations from '@js/vendor/DOMAnimation'

import variables from '@js/variables'
import debug from '@js/modules/debug'

import Cookie from '@js/modules/cookie'
import Navigation from '@js/modules/navigation'
import PageTransition from '@js/modules/pageTransition'
import Pointer from '@js/modules/pointer'
import * as base from '@js/modules/base'

// import $ from 'jquery'

window.Util      = Util
window.debug     = debug
window.throttle  = throttle
// window.debounce  = debounce
window.variables = variables


const SELECTORS = {
  // loaderTextePercent: '#pageloader .pageloader__text-percent',
  // loaderProgress: '#pageloader .pageloader__progress',

  linkWords: '.link-words',
  linkLetters: '.link-letters',

  form: '.wpcf7-form',

  blockReveal: '.blockReveal',
  scrollReveal: '.scrollReveal',
  sliceReveal: '.sliceReveal',

  parallax: '.item-parallax:not(.no-instance)'
}
const CLASSES = {
  loading: 'loading',
  loaded: 'loaded',
  animation: 'fade-in',

  scrollingUp: 'scrollingUp',
  scrollingDown: 'scrollingDown',
  hasScroll: 'hasScroll'
}


// Main App Object
const App = {

  loadTotal    : 0,
  loadProgress : 0,
  modulesToInit: {},

  el : {
    // html: document.documentElement,
    html: RM.Dom.html,
    // body: document.body,
    body: RM.Dom.body,
    // site: document.getElementById('site'),
    site: RM.G.id('site'),
    // main: document.getElementById('main')
    main: RM.G.id('main')
  },

  bounds: {
    window_w     : 0,
    window_h     : 0,
    siteOffsetTop: 0,
    mainOffsetTop: 0
  },

  env: {
    // isIE: navigator.userAgent.indexOf('MSIE ') > 0 || navigator.userAgent.match(/Trident.*rv\:11\./) !== null,
    isIE: RM.Snif.isIE,
    // isMobile: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null,
    isMobile: RM.Snif.isMobile,

    cssVariablesSupport: window.CSS && CSS.supports('color', 'var(--fake-var)'),
    webpSupport: Util.hasClass( document.documentElement, 'webp' ),
    intersectionObserverSupport: "IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype,
    mutationObserverSupport: "MutationObserver" in window,
    idleCallbackSupport: "requestIdleCallback" in window
  },

  MathUtils: {
    // Equation of a line.
    lineEq: (y2, y1, x2, x1, currentVal) => {
      var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
      return m * currentVal + b;
    },
    // Linear Interpolation function.
    lerp: (a, b, n) => (1 - n) * a + n * b,
    // Normalisation function
    norm: (value, min, max) => {
      return (value - min) / (max - min)
    }
  },

  init() {
    const t = this
    Util.addClass(this.el.html, this.env.isIE ? 'isIE' : 'isNotIE' )
    Util.addClass(this.el.html, this.env.isMobile ? 'isMobile' : 'isNotMobile' )
    // Util.removeClass(this.el.html, 'no-js');

    const windowDim = Util.getWindowDimensions()
    this.bounds.window_w = windowDim.width
    this.bounds.window_h = windowDim.height
    this.bounds.siteOffsetTop = this.el.site.offsetTop
    this.bounds.mainOffsetTop = this.bounds.siteOffsetTop + this.el.main.offsetTop

    // this.el.pageloaderProgress = RM.Select.query(SELECTORS.loaderProgress)
    // this.modulesToInit['PageLoader'] = 1
    // this.modulesToInit['Gallery']  = 1
    this.modulesToInit['Lightbox'] = 1
    this.modulesToInit['Smooth']   = 1

    // Init elements
    this.Cookie         = new Cookie()
    this.Navigation     = new Navigation()
    this.PageTransition = new PageTransition()
    this.Pointer        = new Pointer()

    Util.scrollZero()
    this.registerServiceWorker()
    // this.lazyLoading()
    this.initScrollTopButton()
    this.initAncreButton()
    this.initQuicklink()
    this.initResizeEvents()
    this.initScrollEvents()
    this.Pointer.init()

    // All pages > init scripts if there is at least one
    if ( RM.Select.el(SELECTORS.linkWords).length || RM.Select.el(SELECTORS.linkLetters).length ) {
      import('@js/modules/link').then(function (module) {
        const Link = module.default
        t.Link = new Link()
      })
    }
    if ( RM.Select.el(SELECTORS.form).length ) {
      import('@js/modules/form').then(function (module) {
        const Form = module.default
        t.Form = new Form()
      })
    }
    if ( RM.Select.query(SELECTORS.parallax) ) {
      this.modulesToInit['Parallax'] = 1
      import('@js/modules/parallax').then(function (module) {
        const Parallax = module.default
        t.Parallax = new Parallax()
        t.Parallax.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.sliceReveal).length ) {
      this.modulesToInit['SliceReveal'] = 1
      import('@js/modules/sliceReveal').then(function (module) {
        const SliceRevealer = module.default
        t.SliceReveal = new SliceRevealer()
        t.SliceReveal.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.blockReveal).length ) {
      this.modulesToInit['BlockReveal'] = 1
      import('@js/modules/blockReveal').then(function (module) {
        const BlockRevealer = module.default
        t.BlockReveal = new BlockRevealer()
        t.BlockReveal.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.scrollReveal).length ) {
      this.modulesToInit['ScrollReveal'] = 1
      import('@js/modules/scrollReveal').then(function (module) {
        const ScrollRevealer = module.default
        t.ScrollReveal = new ScrollRevealer()
        t.ScrollReveal.initialize()
      })
    }
    if ( RM.G.id('featured-proprietes') ) {
      this.modulesToInit['FeaturedSlider'] = 1
      import('@js/modules/featuredSlider').then(function (module) {
        const FeaturedSlider = module.default
        t.FeaturedSlider = new FeaturedSlider()
        t.FeaturedSlider.initialize()
      })
    }
    if ( RM.Select.el('.column--thumbnails').length ) {
      this.modulesToInit['ProprieteSlider'] = 1
      import('@js/modules/proprieteSlider').then(function (module) {
        const ProprieteSlider = module.default
        t.ProprieteSlider = new ProprieteSlider()
        t.ProprieteSlider.initialize()
      })
    }

    // All pages
    // import('@js/modules/pageLoader').then(function (module) {
    //   const PageLoader = module.default
    //   t.PageLoader = new PageLoader(t)
    // })
    // import('@js/modules/gallery').then(function (module) {
    //   const Gallery = module.default
    //   t.Gallery = new Gallery()
    //   t.Gallery.initialize()
    // })
    import('@js/modules/lightbox').then(function (module) {
      const Lightbox = module.default
      t.Lightbox = new Lightbox()
      t.Lightbox.initialize()
    })
    import('@js/modules/smoothScroll').then(function (module) {
      const Smooth = module.default
      t.Smooth = new Smooth()
      t.Smooth.initialize()
    })

    this.loadTotal += Object.keys(this.modulesToInit).length

    // Propriete Single
    if ( RM.Select.el('.column--form').length ) {
      const ref         = RM.G.id('propriete-reference')
      const agent       = RM.G.id('votreagent')
      const hiddenRef   = RM.G.id('form-propriete-reference')
      const hiddenEmail = RM.G.id('form-agent-email')
      if ( hiddenRef ) {
        hiddenRef.value = ref.dataset.reference
      }
      if ( hiddenEmail ) {
        hiddenEmail.value = agent.dataset.email
      }
    }

    // IE ? Add an alert
    // if ( this.isIE ) {
    //   const ie_p = document.createElement('p')
    //   ie_p.classList.add('browserupgrade')
    //   ie_p.innerHTML = 'You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/" target="_blank">upgrade your browser</a> to improve your experience and security.'
    //   this.el.body.appendChild(ie_p)
    // }
  },

  getScrollY () {
    return window.pageYOffset || document.documentElement.scrollTop;
  },

  // Init Resize Events
  initResizeEvents () {
    const t = this

    RM.BM(this, ['resize'])
    this.ro = new RM.RO({
      cb: this.resize,
      throttleDelay: 200
    })
    this.ro.on()
  },
  resize (e) {
    // debug(e)
    this.bounds.window_w      = window.innerWidth
    this.bounds.window_h      = window.innerHeight
    this.bounds.siteOffsetTop = this.el.site.offsetTop
    this.bounds.mainOffsetTop = this.bounds.siteOffsetTop + this.el.main.offsetTop
  },

  // Init Scroll Events
  // => Scroll Top button
  initScrollEvents () {
    const t = this

    this.scroll = {
      sy             : 0,
      scrollTreshold : 20,
      scrollDirection: false
    }

    RM.L(window, 'a', 'scroll', throttle((e) => {
      var sy = this.getScrollY()

      // Scroll Direction
      if ( sy > this.scroll.sy + this.scroll.scrollTreshold ) {
        this.scroll.scrollDirection = 'down'
        Util.removeClass(this.el.body, CLASSES.scrollingUp)
        Util.addClass(this.el.body, CLASSES.scrollingDown)
        this.scroll.sy = sy
      } else if ( sy < this.scroll.sy - this.scroll.scrollTreshold ) {
        this.scroll.scrollDirection = 'up'
        Util.removeClass(this.el.body, CLASSES.scrollingDown)
        Util.addClass(this.el.body, CLASSES.scrollingUp)
        this.scroll.sy = sy
      }

      // Has Scroll ?
      if ( sy > this.bounds.mainOffsetTop ) {
        Util.addClass(this.el.body, CLASSES.hasScroll)
      } else {
        Util.removeClass(this.el.body, CLASSES.hasScroll)
      }

      // ScrollTop Button
      if ( sy >= (this.bounds.window_h / 3) ) {
        Util.removeClass(this.el.scrollTop, 'invisible')
      } else {
        Util.addClass(this.el.scrollTop, 'invisible')
      }

      // Pointer
      this.Pointer.run()
    }, 60))
  },

  // Init Scroll Top Button
  initScrollTopButton () {
    this.el.scrollTop = RM.G.id('scrollTop')
    // RM.L(this.el.scrollTop, 'a', 'click', () => Util.scrollToTop())
    // RM.L(this.el.scrollTop, 'a', 'click', (e) => {
    //   e.preventDefault()
    //   RM.ScrollToTop({
    //     h: 0,
    //     // cb: afterTop
    //   })
    // })

    RM.L(this.el.scrollTop, 'a', 'click', (e) => {
      e.preventDefault()
      Util.scrollToTop()
    })

    // const onScrollTopClick = e => {
    //   window.scroll({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth'
    //   })
    //   e.preventDefault()
    // }
    // this.el.scrollTop.addEventListener('click', onScrollTopClick)
    // RM.L(this.scrollTop, 'a', 'click', onScrollTopClick)
  },

  // Init link with #idOfAnElement
  // => scroll to this element
  initAncreButton () {
    this.el.linksAncre = Array.from( document.querySelectorAll('a[href^="#"]:not(#cn-accept-cookie):not(.scrollTop):not(.tabs__link):not(.screen-reader-text):not(.screen-reader-shortcut)') )

    // CallBack Function
    const ancre_click = e => {
      const target = document.querySelector( e.target.getAttribute('href') )

      if ( target ) {
        e.preventDefault()

        // target.scrollIntoView({
        //   behavior: 'smooth'
        // })
        // Util.scrollTo( target.offsetTop )
        RM.ScrollTo({
          dest: target.offsetTop,
          d: variables.transition,
          e: 'o3',
          // cb: afterTop
        })
      }
    }

    const initAncreClickForElem = item => {
      // item.addEventListener('click', ancre_click)
      RM.L(item, 'a', 'click', ancre_click)
    }

    // Add Event Listener to links
    this.el.linksAncre.forEach( link => initAncreClickForElem(link) )
  },

  // Init Quicklink
  // => prefetch in-viewport links during idle time
  // https://github.com/GoogleChromeLabs/quicklink
  initQuicklink () {
    if (! this.env.intersectionObserverSupport ) {
      return
    }
    RM.L(window, 'a', 'load', () => {
      import('quicklink').then(function (module) {
        const quicklink = module.default
        quicklink({
          // origins: [
          //   site_url.replace(/(^\w+:|^)\/\//, '')
          // ], // default to : [location.hostname]
          ignores: [
            /\/api\/?/,
            /\/wp-admin\/?/,
            /\/wp-login\/?/,
            uri => uri.includes('.zip'),
            uri => uri.includes('#'),
            (uri, elem) => elem.hasAttribute('noprefetch')
          ]
        })
      })
    })
  },

  // lazyLoading() {
  //     this.LazyLoading = new Blazy({
  //         selector: '.lazyload',
  //         successClass: 'loaded'
  //     })
  // },

  // displayLoadProgress () {
  //   let progress = 0,
  //       progress_base = 0
  //   if ( this.loadTotal > 0 ) {
  //     progress_base = this.loadProgress / this.loadTotal
  //     progress = progress_base * 100
  //   }

  //   // this.pageloaderTextPercent.innerHTML = Math.round( progress ) + '%'
  //   this.el.pageloaderProgress.style.transform = 'scaleX(' + progress_base + ')'
  // },

  checkAppLoaded () {
    const t = this
    let isLoaded = true

    // if (isLoaded && this.modulesToInit['PageLoader']) {
    //   isLoaded = typeof this.PageLoader != 'undefined' && this.PageLoader.isLoaded
    // }
    // if (isLoaded && this.modulesToInit['Gallery']) {
    //   isLoaded = typeof this.Gallery != 'undefined' && this.Gallery.isLoaded
    // }
    if (isLoaded && this.modulesToInit['Lightbox']) {
      isLoaded = typeof this.Lightbox != 'undefined' && this.Lightbox.isLoaded
    }
    if (isLoaded && this.modulesToInit['Smooth']) {
      isLoaded = typeof this.Smooth != 'undefined' && this.Smooth.isLoaded
    }
    if (isLoaded && this.modulesToInit['Parallax']) {
      isLoaded = typeof this.Parallax != 'undefined' && this.Parallax.isLoaded
    }
    if (isLoaded && this.modulesToInit['FeaturedSlider']) {
      isLoaded = typeof this.FeaturedSlider != 'undefined' && this.FeaturedSlider.isLoaded
    }
    if (isLoaded && this.modulesToInit['ProprieteSlider']) {
      isLoaded = typeof this.ProprieteSlider != 'undefined' && this.ProprieteSlider.isLoaded
    }
    if (isLoaded && this.modulesToInit['ScrollReveal']) {
      isLoaded = typeof this.ScrollReveal != 'undefined' && this.ScrollReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['BlockReveal']) {
      isLoaded = typeof this.BlockReveal != 'undefined' && this.BlockReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['SliceReveal']) {
      isLoaded = typeof this.SliceReveal != 'undefined' && this.SliceReveal.isLoaded
    }

    // this.displayLoadProgress()

    if ( isLoaded ) {
      Util.removeClass(this.el.body, CLASSES.loading)
      Util.addClass(this.el.main, CLASSES.animation)
      this.copyright()

      // Propriete Archives
      const filter = RM.G.id('btn-open-filter')
      if ( this.bounds.window_w < 768 && filter ) {
        const form = filter.parentNode.querySelector('.searchandfilter')
        form.style.display = 'none'

        RM.L(filter, 'a', 'click', e => {
          e.preventDefault()
          DOMAnimations.slideToggle(form)
          Util.toggleClass(filter, 'isOpen', !Util.hasClass(filter, 'isOpen'))
          return false
        })
      }

      if ( t.modulesToInit['FeaturedSlider'] ) t.FeaturedSlider.init()
      if ( t.modulesToInit['ProprieteSlider'] ) t.ProprieteSlider.init()

      // console.time('loaded')
      const delay = new RM.Delay(_ => {
        Util.addClass(t.el.body,CLASSES.loaded)
        // console.timeEnd('loaded')
        // RM.G.id('pageloader').remove()

        if ( t.modulesToInit['ScrollReveal'] ) t.ScrollReveal.init()
        if ( t.modulesToInit['BlockReveal'] ) t.BlockReveal.init()
        if ( t.modulesToInit['SliceReveal'] ) t.SliceReveal.init()
        if ( t.modulesToInit['Parallax'] ) t.Parallax.init()
        if ( t.modulesToInit['Smooth'] ) t.Smooth.init()
        new base.PositionFull()
        new base.Tabs()
        new base.Toggles()
      }, variables.transition_slow)
      delay.run()
    }
  },

  // check for SW support and register
  registerServiceWorker () {
    if ('serviceWorker' in navigator) {
      const t = this
      // const scope = '/';
      const scope = '/Lab/wp-bao-theme-v2/'

      // Delay registration until after the page has loaded, to ensure that our
      // precaching requests don't degrade the first visit experience.
      // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
      window.addEventListener('load', function () {
        // Your service-worker.js *must* be located at the top-level directory relative to your site.
        // It won't be able to control pages unless it's located at the same level or higher than them.
        // See https://github.com/slightlyoff/ServiceWorker/issues/468
        /*// navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
        navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
          debug('Service Worker registered successfully.')

          // updatefound is fired if service-worker.js changes.
          reg.onupdatefound = function() {
            // The updatefound event implies that reg.installing is set; see
            // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
            var installingWorker = reg.installing

            installingWorker.onstatechange = function() {
              switch (installingWorker.state) {
                case 'installed':
                  if (navigator.serviceWorker.controller) {
                      // At this point, the old content will have been purged and the fresh content will
                      // have been added to the cache.
                      // It's the perfect time to display a "New content is available; please refresh."
                      // message in the page's interface.
                      debug('New or updated content is available.')
                  } else {
                      // At this point, everything has been precached.
                      // It's the perfect time to display a "Content is cached for offline use." message.
                      debug('Content is now available offline!')
                  }
                  break;

                case 'redundant':
                  console.error('The installing service worker became redundant.')
                  break;
              }
            };
          };
        }).catch(function(e) {
            console.error('Error during service worker registration:', e)
        })*/


        // Background Sync
        // navigator.serviceWorker.ready.then((swRegistration) => {
        //   return swRegistration.sync.register('event1')
        // })
      })
    }
  },

  copyright () {
    const style = [
      'color: #fff',
      'background: #ffc308',
      'padding: 4px 8px',
      'border-left: 5px solid #282828',
      'border-right: 5px solid #282828'
    ].join(';')
    debug('%cA creation by LM Creative & Boite à Oeufs 🇫🇷', style)
    debug('%c> Site : http://lmcreative.fr/', style)
    debug('%c> Site : https://www.boite-a-oeufs.com', style)
    debug('%c> Twitter : https://twitter.com/BoiteOeufs', style)
    debug('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)
  }
}
window.App = App;

// Kick shit off
App.init();


window.onpageshow = function(event) {
  if (event.persisted) {
    // window.location.reload()

    Util.removeClass(App.el.body, CLASSES.loading)
    Util.addClass(App.el.main, CLASSES.animation)
  }
};


// Search & Filter Pro
// Detects when the ajax request has finished and the content has been updated
// - add scripts that apply to your results here
(function ( $ ) {
  $(document).on("sf:ajaxfinish", ".searchandfilter", function(){
    // const t = App
    // debug('sf:ajaxfinish')

    // Init LQ Media & Lazyload
    if (window.lowqualitify) {
      window.lowqualitify.reset()
    }

    App.Pointer.init()

    if ( App.modulesToInit['ScrollReveal'] ) {
      App.ScrollReveal.elems = Array.from( RM.Select.el(SELECTORS.scrollReveal, '#proprieteslist') )
      App.ScrollReveal.init()
    }
    if ( App.modulesToInit['BlockReveal'] ) {
      App.BlockReveal.elems   = Array.from( RM.Select.el(SELECTORS.blockReveal, '#proprieteslist') )
      App.BlockReveal.reveals = []
      App.BlockReveal.init()
    }
    if ( App.modulesToInit['SliceReveal'] ) {
      App.SliceReveal.elems   = Array.from( RM.Select.el(SELECTORS.sliceReveal, '#proprieteslist') )
      App.SliceReveal.reveals = []
      App.SliceReveal.init()
    }

    App.Smooth.reInitialize()
  });

  if ( $('.form__select--chosen').length ) {
    import('chosen-js').then(function (module) {
      // const chosen = module.default
      $('.form__select--chosen').chosen({
        no_results_text: 'Oups, aucun résultat !'
      });

      $(window).resize(function() {
        $('.form__select--chosen').trigger("chosen:updated");
        $('.sf-input-select').trigger("chosen:updated");
      })

      // https://wpmarmite.com/popup-wordpress/
      // https://docs.wppopupmaker.com/article/42-popup-maker-jquery-api
      $(window).on('pumAfterOpen', function() {
        setTimeout(function() {
          $(window).trigger('resize');
          $('.pum .chosen-container').css('width', '100%');
        }, 500)
      })
    })
  }

  var $reference_select = $('#search-filter-form-reference .sf-input-select');
  $reference_select.change(function() {
    var $option = $reference_select.find('option:selected');
    if ($option.val() != '') {
      window.location.href = $option.data('url');
    }
  });

}(jQuery));