const SELECTORS = {
    pageLinks: 'a[href]'
}

const CLASSES = {
    gallery: 'js_gallery',
    lightbox: 'lightbox',
    scrollTop: 'scrollTop',
    pageNumbers: 'page-numbers',

    loaded: 'loaded',
    loading: 'loading',
    pagetransition: 'pagetransition'
}

export default class PageTransition {
    constructor () {
        this.pageLinks = Array.from( document.querySelectorAll(SELECTORS.pageLinks) )
        if (this.pageLinks.length) {
            this.init()
        }
    }

    init() {
        const onLinkClick = e => {
            const link = Util.findParentByTagName(e.target || e.srcElement, 'A')

            if ( !Util.hasClass(link, CLASSES.lightbox) && !Util.hasClass(link, CLASSES.gallery) ) {
                // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
                // and open the image in a new tab
                if (e.metaKey || e.ctrlKey || e.target.getAttribute('target')) {
                    // return w.open(element.src, '_blank');
                    return
                }

                // e.preventDefault()

                Util.addClass(App.el.html, CLASSES.pagetransition)
                Util.removeClass(App.el.body, CLASSES.loaded)
                Util.addClass(App.el.body, CLASSES.loading)

                // setTimeout(() => {
                //     document.body.classList.add(CLASSES.loading)
                // }, 25)
                // const delay = new RM.Delay(_ => {
                //     Util.addClass(App.el.body, CLASSES.loading)
                // }, 25)
                // delay.run()

                // const transition = () => {
                //     window.location = href
                // }
                // Util.scrollToTop(400, transition)
            }
        }

        this.pageLinks.forEach(l => {
            const href = l.href

            if ( !Util.hasClass(l, CLASSES.scrollTop) && !Util.hasClass(l, CLASSES.lightbox) && !Util.hasClass(l, CLASSES.gallery) && !Util.hasClass(l, CLASSES.pageNumbers) && !Util.hasClass(l, 'cn-button') && ( Util.isSiteUrl(href) && href.indexOf('#') === -1 ) && href.indexOf('.zip') === -1 ) {
                l.addEventListener('click', onLinkClick)
            }
        })
    }
}
